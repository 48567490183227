<template>
    <div style="height: 80vh;" ref="mainDiv" id="mainDivElement">
        <div class="d-flex justify-content-end reports">
            <b-dropdown ref="dropdownMenuFilters" variant="primary" :text="$t('reporting.filters')" right style="margin-bottom: 15px;" menu-class="dropdownWidth">

                <div class="pl-2 pt-2" :style="'width:' + mainDivWidth + 'px !important;'">
                    <b-form-checkbox v-if="this.selected_report === REPORT_IDS.InventorySheet" v-model="printCurrentStock" switch>
                        <template #default>
                            <span class="text-dark">{{ $t('general.print_current_stock') }}</span>
                        </template>
                    </b-form-checkbox>
                </div>

                <div class="p-2" :style="'width:' + mainDivWidth + 'px !important;'">
                    <validation-observer ref="validation">
                        <div class="row">
                            <div v-if="selected_report !== 'stock' && showDatePicker()" class="col-6 col-md-3">
                                <b-form-group :label="$t('general.date_from')">
                                    <validation-provider #default="{ errors }" :name="$t('general.date_from')" :rules="`required|max_value:@${$t('general.date_from')}`" >
                                        <date-picker :locale="currentLocale" :first-day-of-week="2" :max-date='dateTo' disabled-dates is24hr v-model="dateFrom" :masks="{ input: 'DD.MM.YYYY' }" :is-required="true">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control"
                                                       :value="inputValue"
                                                       v-on="inputEvents"
                                                />
                                            </template>
                                        </date-picker>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div v-else-if="showDatePicker()" class="col-6 col-md-3">
                                <b-form-group :label="$t('general.date_to')">
                                    <validation-provider #default="{ errors }" :name="$t('general.date_to')" rules="required">
                                        <date-picker :locale="currentLocale" :first-day-of-week="2" disabled-dates is24hr v-model="dateTo" :masks="{ input: 'DD.MM.YYYY' }" :is-required="true" >
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control"
                                                       :value="inputValue"
                                                       v-on="inputEvents"
                                                />
                                            </template>
                                        </date-picker>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div v-if="selected_report !== 'stock' && showDatePicker()" class="col-6 col-md-3">
                                <b-form-group :label="$t('general.date_to')">
                                    <validation-provider #default="{ errors }" :name="$t('general.date_to')" :rules="`required|min_value:@${$t('general.date_from')}`">
                                        <date-picker :locale="currentLocale" :first-day-of-week="2" :min-date='dateFrom' disabled-dates is24hr v-model="dateTo" :masks="{ input: 'DD.MM.YYYY' }" :is-required="true">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control"
                                                       :value="inputValue"
                                                       v-on="inputEvents"
                                                />
                                            </template>
                                        </date-picker>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-6 col-md-3" v-if="showBuyerPicker()">
                                <b-form-group :label="$t('general.buyer')">
                                    <validation-provider #default="{ errors }" :name="$t('general.buyer')" rules="required">
                                        <v-select class="floating-filter w-100" appendToBody v-model="buyer" label="name" :options="buyers" :clearable="false">
                                            <template #selected-option="{name}">
                                                <span  class="textCutSelect">
                                                    {{ name }}
                                                </span>
                                            </template>
                                        </v-select>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-6 col-md-3" v-if="showEmployeePicker()">
                                <b-form-group :label="$t('general.employees')">
                                    <validation-provider #default="{ errors }" :name="$t('general.employees')" rules="required">
                                        <v-select class="floating-filter w-100" appendToBody v-model="employee" label="name" :options="employees" :clearable="false">
                                            <template #selected-option="{name}">
                                                <span  class="textCutSelect">
                                                    {{ name }}
                                                </span>
                                            </template>
                                        </v-select>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div class="d-flex align-items-center pt-1 col-6 col-md-3 pl-2" :style="'width:' + mainDivWidth + 'px !important;'" v-if="showEventsCheckbox()">
                                <b-form-checkbox v-model="showEvents">Natisni dogodke</b-form-checkbox>
                            </div>

                            <div class="col-6 col-md-3" v-if="filters && filters.length > 0 && filters.includes('business_units')">
                                <b-form-group :label="$t('general.business_unit')" >
                                    <validation-provider #default="{ errors }" :name="$t('general.business_unit')" rules="required">
                                        <v-select appendToBody v-model="selectedBusinessUnit" :selectable="ele => !(ele.id == $NilObjectId && filters.includes('disable_all_business_units'))" label="name" :options="businessUnits" @input="unitChanged" :clearable="false"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            
                            <div class="col-6 col-md-3"  style="width: 50%" v-if="selectedBusinessUnit && selectedBusinessUnit.id != $NilObjectId && filters && filters.length > 0 && filters.includes('cash_registers')" >
                                <b-form-group :label="$t('general.cash_register')">
                                    <validation-provider #default="{ errors }" :name="$t('general.cash_register')" rules="required">
                                        <v-select appendToBody v-model="selectedCashRegister" label="cash_register_id" :options="cashRegistersOptions" />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-3"  style="width: 50%" v-if="selectedBusinessUnit && selectedBusinessUnit.id != $NilObjectId && filters && filters.length > 0 && filters.includes('price_list')" >
                                <b-form-group :label="$t('general.price_list')">
                                    <validation-provider #default="{ errors }" :name="$t('general.price_list')" rules="required">
                                        <v-select appendToBody v-model="selectedPriceList" label="name" :options="priceLists" />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-3"  style="width: 50%" v-if="filters && filters.length > 0 && filters.includes('ingredients')" >
                                <b-form-group :label="$t('general.ingredient')">
                                    <validation-provider #default="{ errors }" :name="$t('general.ingredient')" rules="required">
                                        <v-select appendToBody v-model="selectedIngredient" label="name" :options="ingredients" >
                                            <template #option="{ name, ingredient_code }">
                                                <div>{{ ingredient_code }} - {{ name }}</div>
                                            </template>

                                            <template #selected-option="{ name, ingredient_code }">
                                                <div>{{ ingredient_code }} - {{ name }}</div>
                                            </template>
                                        </v-select>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div class="col-6 col-md-3"  style="width: 50%" v-if="showWarehouseFilter">
                                <b-form-group :label="$t('general.warehouse')">
                                    <validation-provider #default="{ errors }" :name="$t('general.warehouse')" rules="required">
                                        <v-select appendToBody v-model="selectedWarehouse" :selectable="ele => !(ele.id == $NilObjectId && filters.includes('disable_all_warehouses'))" label="name"  :options="warehouses" />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="col-6 col-md-3"  style="width: 50%" v-if="filters && filters.length > 0 && filters.includes('warehouses_group') && selectedWarehouse !== null" >
                                <b-form-group :label="$t('reporting.view')">
                                    <validation-provider #default="{ errors }" :name="$t('reporting.view')" rules="required">
                                        <v-select appendToBody v-model="selected_warehouses_group_option" label="name" :options="warehouses_group_options" />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="mt-1">
                            <div class="mb-2" v-if="showIncludeAdditionalPaymentMethods()">
                                <b-form-checkbox v-model="includeAdditionalPaymentMethods" switch>
                                    <template #default>
                                        <span>{{$t('general.include_additional_payment_methods')}}</span>
                                    </template>
                                </b-form-checkbox>
                            </div>
                            <div v-if="showTimePicker()" class="d-flex justify-content-start mb-1">
                                <b-form-group>
                                    <validation-provider #default="{ errors }" :name="$t('general.start_time')" rules="required">
                                        <b-form-radio-group v-model="isStartWorkingHour" :options="optionsTime" name="Select time"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                            <div class="row">
                                <div v-if="showTimePicker() && !isStartWorkingHour" class="col-6 col-md-3">
                                    <b-form-group :label="$t('reporting.hour_from')">
                                        <validation-provider #default="{ errors }" :name="$t('reporting.hour_from')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" class="timePickerNoDate" mode="time" disabled-dates is24hr v-model="dateFrom" :mask="{ title: 'HH MM' }" :is-required="true">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="inputValue"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div v-if="showTimePicker() && !isStartWorkingHour" class="col-6 col-md-3">
                                    <b-form-group :label="$t('reporting.hour_to')">
                                        <validation-provider #default="{ errors }" :name="$t('reporting.hour_to')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" class="timePickerNoDate" mode="time" disabled-dates is24hr :is-required="true" v-model="dateTo" :mask="{
                                                title: 'HH MM'
                                            }"

                                            >
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="inputValue"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                            </div>

                        </div>

                    </validation-observer>
                    <div class="d-flex justify-content-end align-items-end">
                        <b-button @click="confirm()">{{$t('general.accept')}}</b-button>
                    </div>
                </div>
            </b-dropdown>
        </div>
        <div class="row p-0 m-0 h-100 w-100">
            <div class="col-4 pl-0 w-100 h-100">

                <b-card class="cardBodyNoPadding p-0 m-0 h-100 w-100">
                    <div class="p-2" style="height: 100%; min-height: 100%; overflow-y: auto;">
                        <tree-view color="#b4bfb5" :data="computedData" @clicked="itemClicked" ></tree-view>
                    </div>
                </b-card>
            </div>
            
            <b-card class="col-8 w-100 h-100 mainWrapper mb-5" style="max-height: 100%; overflow-y: auto">
                <b-overlay :show="showLoader">
                    <div class="d-flex justify-content-end pb-1" v-if="this.selected_report">
                        <b-button v-if="this.selected_report !== REPORT_IDS.FursDateToDateReport" @click="loadReport('pdf')">{{$t('reporting.download_pdf')}}</b-button>
                        <b-button class="ml-2" v-if="this.selected_report !== REPORT_IDS.FursDateToDateReport && this.selected_report !== REPORT_IDS.InternalAct && this.selected_report !== REPORT_IDS.Tips && this.selected_report !== REPORT_IDS.TipsByEmployee && this.selected_report !== REPORT_IDS.EmployeeTips" @click="loadReport('xlsx')">{{$t('reporting.download_excel')}}</b-button>
                    </div>

                    <div class="w-100 mb-5" v-html="html">
                    </div>
                </b-overlay>
            </b-card>
           
        </div>
    </div>
</template>

<script>
    import {BCard, BFormGroup, BButton, BDropdown, BOverlay, BFormRadioGroup, BFormCheckbox} from 'bootstrap-vue'
    import TreeView from '@/views/components/TreeView/TreeView.vue'
    import VSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    import {DatePicker} from 'v-calendar'
    import dayjs from 'dayjs'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import i18n from '@/i18n/i18n'
    import {getOptionsTimeSelect} from '@/libs/constants/material.js'

    const {nextTick} = require('@vue/composition-api')
    const DATE_FORMAT = 'YYYY-MM-DD'

    const FILTERS = {
        Business_units: 'business_units',
        Cash_registers: 'cash_registers',
        Ingredients: 'ingredients',
        Warehouses: 'warehouses',
        Warehouses_group: 'warehouses_group',
        DisableAllWarehouses: 'disable_all_warehouses',
        PriceList: 'price_list',
        DisableTimestamp: 'disable_timestamp',
        DisableAllBusinessUnits: 'disable_all_business_units',
        DisableDate: 'disable_date',
        PrintCurrentStock: 'print_current_stock',
        Buyer: 'buyer',
        Employee: 'employee',
        FilterShowFilterOnClick: 'show_filter_on_click'
    }

    export default {
        components:{
            BFormCheckbox,
            BFormRadioGroup,
            TreeView,
            BCard,
            BFormGroup,
            BButton,
            BDropdown,
            VSelect,
            DatePicker,
            ValidationProvider,
            ValidationObserver,
            BOverlay
        },
        watch: {
            async collapsedMenu() {
                const thisIns = this
                setTimeout(function() {
                    nextTick(() => thisIns.windowResized())
                }, 700)
            },
            selectedBusinessUnit() {
                this.selectedCashRegister = null
                if (!this.selectedBusinessUnit) return
                this.cashRegistersOptions = this.cashRegisters.filter((item) => item.business_unit_id === this.selectedBusinessUnit.id)
                if (this.cashRegistersOptions.length > 0) {
                    this.cashRegistersOptions.unshift(this.getAllCashRegistersHeader)
                } else {
                    this.cashRegistersOptions.unshift(this.getNoActiveCashRegistersHeader)
                }
            },
            currentLocale() {
                this.html = ''
                this.selected_report = ''
                this.loadData()
                this.loadPriceLists()
                if (this.cashRegistersOptions.length && this.cashRegistersOptions.length > 0) {
                    this.cashRegistersOptions[0] = this.getAllCashRegistersHeader
                }
                if (this.selectedBusinessUnit && this.selectedBusinessUnit.id === this.$NilObjectId) {
                    this.selectedBusinessUnit = this.getAllBusinessUnitsHeader
                }
                if (this.selectedCashRegister && this.selectedCashRegister.cash_register_id) {
                    this.selectedCashRegister = this.getAllCashRegistersHeader
                }
                if (this.selectedPriceList && this.selectedPriceList.id) {
                    this.selectedPriceList = this.getDefaultPriceList
                }
                if (this.selectedWarehouse && this.selectedWarehouse.id) {
                    this.selectedWarehouse = this.getAllWarehousesHeader
                }
                if (this.selected_warehouses_group_option) {
                    this.selected_warehouses_group_option = this.warehouses_group_options.find(el => el.id === this.selected_warehouses_group_option.id)
                }
            }
        },
        computed: {
            getDefaultPriceList() {
                return {
                    id: this.$NilObjectId,
                    name: `${i18n.t('reporting.default_price_list')}`
                }
            },
            getNoActiveCashRegistersHeader() {
                return { cash_register_id: `${i18n.t('reporting.no_active_cash_registers')}`, id: this.$NilObjectId}
            },
            getAllCashRegistersHeader() {
                return { cash_register_id: `${i18n.t('reporting.all_cash_registers')}`, id: this.$NilObjectId}
            },
            getAllBusinessUnitsHeader() {
                return { name: `${i18n.t('reporting.all_business_units')}`, id: this.$NilObjectId}
            },
            getAllWarehousesHeader() {
                return {name: `${i18n.t('reporting.all_warehouses')}`, id: this.$NilObjectId}
            },
            optionsTime() {
                return getOptionsTimeSelect()
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            warehouses_group_options() {
                return [{name: this.$t('reporting.single_table'), id: 1}, {name: this.$t('reporting.separate_tables'), id: 2}]
            },
            Tiers() {
                return Tiers
            },
            collapsedMenu() {
                return this.$store.getters['verticalMenu/getVerticalMenuState']
            },
            showWarehouseFilter() {
                if (this.selected_report === this.REPORT_IDS.InventorySheet && this.printCurrentStock && this.filters && this.filters.length > 0 && this.filters.includes('warehouses')) {
                    return true
                } else return !!(this.selected_report !== this.REPORT_IDS.InventorySheet && this.filters && this.filters.length > 0 && this.filters.includes('warehouses'))
            },
            computedData() {
                return  [
                    {
                        id: 'issued_bills',
                        label: i18n.t('reports.sales'),
                        child: [
                            {
                                id: 'end_of_day_report_combined',
                                label: this.$t('reports.end_of_day_report'),
                                child: [
                                    {
                                        id: 'end_of_day_report',
                                        label: this.$t('reports.joined'),
                                        filters: [
                                            FILTERS.Business_units,
                                            FILTERS.Cash_registers
                                        ]
                                    },
                                    {
                                        id: 'end_of_day_report_each_day',
                                        label: this.$t('reports.by_day'),
                                        filters: [
                                            FILTERS.Business_units,
                                            FILTERS.Cash_registers
                                        ]
                                    }
                                ]
                            },
                            {
                                id: 'products_sold_by_category',
                                label: this.$t('reports.products_sold_by_category'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'payment_types_employee_turnover_report',
                                label: this.$t('reports.payment_types_employee_turnover_report'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'payment_types_turnover_combined',
                                label: this.$t('reports.payment_types_turnover'),
                                child: [
                                    {
                                        id: 'payment_types_turnover',
                                        label: this.$t('reports.joined'),
                                        filters: [
                                            FILTERS.Business_units,
                                            FILTERS.Cash_registers
                                        ]
                                    },
                                    {
                                        id: 'payment_types_turnover_each_day',
                                        label: this.$t('reports.by_day'),
                                        filters: [
                                            FILTERS.Business_units,
                                            FILTERS.Cash_registers
                                        ]
                                    }
                                ]
                            },
                            {
                                id: 'products_turnover',
                                label: this.$t('reports.products_turnover'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'category_turnover',
                                label: this.$t('reports.category_turnover'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'reversal_bills_turnover',
                                label: this.$t('reports.reversal_bills_turnover'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'cash_register_turnover',
                                label: this.$t('reports.cash_register_turnover'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'most_sold_product',
                                label: this.$t('reports.most_sold_product'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'most_sold_product_by_employee',
                                label: this.$t('reports.most_sold_product_by_employee'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'products_sold_by_hour',
                                label: this.$t('reports.products_sold_by_hour'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            }
                        ]
                    },
                    {
                        id: 'invoices',
                        label: this.$t('general.invoices'),
                        child: [
                            {
                                id: 'issued_invoices',
                                label: this.$t('reports.issued_invoices'),
                                filters: [FILTERS.FilterShowFilterOnClick]
                            },
                            {
                                id: 'unpaid_invoices',
                                label: this.$t('reports.unpaid_invoices'),
                                filters: [FILTERS.FilterShowFilterOnClick]
                            },
                            {
                                id: 'invoices_by_buyer',
                                label: this.$t('reports.invoices_by_buyer'),
                                filters: [FILTERS.Buyer]
                            },
                            {
                                id: 'invoices_grouped_by_buyer',
                                label: this.$t('reports.invoices_grouped_by_buyer'),
                                filters: [FILTERS.FilterShowFilterOnClick]
                            },
                            {
                                id: 'invoices_in_preparation',
                                label: this.$t('reports.invoices_in_preparation'),
                                filters: [FILTERS.FilterShowFilterOnClick]
                            },
                            {
                                id: 'unissued_invoices',
                                label: this.$t('reports.unissued_invoices'),
                                filters: [FILTERS.FilterShowFilterOnClick]
                            }
                        ]
                    },
                    {
                        id: 'warehouse',
                        label: this.$t('general.warehouse'),
                        child: [
                            {
                                id: 'stock',
                                label: this.$t('reports.stock'),
                                filters: [
                                    FILTERS.Warehouses,
                                    FILTERS.Warehouses_group
                                ]
                            },
                            {
                                id: 'used_ingredients_by_day',
                                label: this.$t('reports.used_ingredients_by_day'),
                                filters: [FILTERS.Business_units]
                            },
                            {
                                id: 'ingredients_report_card',
                                label: this.$t('reports.ingredients_report_card'),
                                filters: [FILTERS.Warehouses, FILTERS.Ingredients, FILTERS.DisableAllWarehouses]
                            },
                            {
                                id: 'purchased_ingredients_turnover',
                                label: this.$t('reports.purchased_ingredients'),
                                filters: []
                            },
                            {
                                id: 'purchased_ingredients_by_suppliers',
                                label: this.$t('reports.purchased_ingredients_by_suppliers'),
                                filters: [FILTERS.Warehouses]
                            },
                            {
                                id: 'inventory_sheet',
                                label: this.$t('reports.inventory_sheet'),
                                filters: [FILTERS.PrintCurrentStock, FILTERS.Warehouses]
                            },
                            {
                                id: 'delivery_notes',
                                label: this.$t('reports.delivery_notes'),
                                filters: []
                            }
                        ],
                        hasPermission: this.$hasTierPermission(Tiers.Standard, Tiers.Premium)
                    },
                    {
                        id: 'price_lists',
                        label: this.$t('general.price_lists'),
                        child: [
                            {
                                id: 'price_list',
                                label: this.$t('general.price_list'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.PriceList,
                                    FILTERS.DisableTimestamp,
                                    FILTERS.DisableAllBusinessUnits
                                ]
                            }
                        ]
                    },
                    {
                        id: 'tip',
                        label: this.$t('general.tips'),
                        child: [
                            {
                                id: 'tips',
                                label: this.$t('general.tips_together'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'tip_by_employee',
                                label: this.$t('general.tips_by_employee'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            },
                            {
                                id: 'tips_by_employee',
                                label: this.$t('general.tips_by_day_for_employee'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers,
                                    FILTERS.Employee
                                ]
                            }
                        ],
                        hasPermission: this.$hasTierPermission(Tiers.Standard, Tiers.Premium)
                    },
                    {
                        id: 'data',
                        label: this.$t('general.data'),
                        child: [
                            {
                                id: 'ingredients',
                                label: this.$t('general.ingredients'),
                                hasPermission: this.$hasTierPermission(Tiers.Standard, Tiers.Premium)
                            },
                            {
                                id: 'employees',
                                label: this.$t('general.employees')
                            },
                            {
                                id: 'payment_types',
                                label: this.$t('general.payment_types')
                            },
                            {
                                id: 'discounts',
                                label: this.$t('general.discounts')
                            },
                            {
                                id: 'display_groups',
                                label: this.$t('general.display_groups'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.DisableAllBusinessUnits
                                ]
                            },
                            {
                                id: 'products',
                                label: this.$t('general.products')
                            },
                            {
                                id: 'products_ingredients',
                                label: this.$t('reports.products_ingredients'),
                                filters: [],
                                hasPermission: this.$hasTierPermission(Tiers.Standard, Tiers.Premium)
                            },
                            {
                                id: 'addons',
                                label: this.$t('general.additions')
                            },
                            {
                                id: 'wolt_integration',
                                label: this.$t('reports.wolt_integration'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.DisableAllBusinessUnits
                                ]
                            }
                        ]
                    },
                    {
                        id: 'employees_time',
                        label: this.$t('reports.employees_time'),
                        child: [
                            {
                                id: 'employees_time_together',
                                label: this.$t('reports.employees_time_together'),
                                filters: [FILTERS.Employee]
                            },
                            {
                                id: 'employees_time_per_day',
                                label: this.$t('reports.employees_time_per_day'),
                                filters: [FILTERS.Employee]
                            }
                        ]
                    },
                    {
                        id: 'internal_act',
                        label: this.$t('reports.internal_act')
                    },
                    {
                        id: 'furs_csv',
                        label: this.$t('reports.furs'),
                        child: [
                            {
                                id: 'furs_date_to_date_report',
                                label: this.$t('reports.furs_date_to_date_report'),
                                filters: [
                                    FILTERS.Business_units,
                                    FILTERS.Cash_registers
                                ]
                            }
                        ]
                    }
                ]
            }
        },
        data() {
            return {
                warehouseConfig: null,
                REPORT_IDS: {
                    EndOfDayReport: 'end_of_day_report',
                    EndOfDayReportEachDay: 'end_of_day_report_each_day',
                    IngredientsReportCard: 'ingredients_report_card',
                    FursDateToDateReport: 'furs_date_to_date_report',
                    PaymentTypesEmployeeTurnoverReport: 'payment_types_employee_turnover_report',
                    ProductsTurnover: 'products_turnover',
                    CategoryTurnover: 'category_turnover',
                    ReversalBillsTurnover: 'reversal_bills_turnover',
                    CashRegisterTurnover: 'cash_register_turnover',
                    PurchasedIngredients: 'purchased_ingredients_turnover',
                    PurchasedIngredientsBySuppliers: 'purchased_ingredients_by_suppliers',
                    Stock: 'stock',
                    MostSoldProduct: 'most_sold_product',
                    MostSoldProductByEmployee: 'most_sold_product_by_employee',
                    PaymentTypesTurnover: 'payment_types_turnover',
                    PaymentTypesTurnoverEachDay: 'payment_types_turnover_each_day',
                    ProductsSoldByHour: 'products_sold_by_hour',
                    UsedIngredientsByDay: 'used_ingredients_by_day',
                    PriceList: 'price_list',
                    ProductsSoldByCategory: 'products_sold_by_category',
                    InternalAct: 'internal_act',
                    ProductsIngredients: 'products_ingredients',
                    IssuedInvoices: 'issued_invoices',
                    UnpaidInvoices: 'unpaid_invoices',
                    InvoicesByBuyer: 'invoices_by_buyer',
                    InvoicesGroupedByBuyer: 'invoices_grouped_by_buyer',
                    InvoicesInPreparation: 'invoices_in_preparation',
                    UnissuedInvoices: 'unissued_invoices',
                    InventorySheet: 'inventory_sheet',
                    Ingredients: 'ingredients',
                    Employees: 'employees',
                    PaymentTypes: 'payment_types',
                    Discounts: 'discounts',
                    DisplayGroups: 'display_groups',
                    Products: 'products',
                    Addons: 'addons',
                    DeliveryNotes: 'delivery_notes',
                    WoltIntegration: 'wolt_integration',
                    Tips:'tips',
                    EmployeeTips:'tip_by_employee',
                    TipsByEmployee:'tips_by_employee',
                    EmployeesTimeTogether: 'employees_time_together',
                    EmployeesTimePerDay: 'employees_time_per_day'
                },
                isStartWorkingHour: true,
                mainDivWidth: 0,
                showLoader: false,
                businessUnits: [],
                cashRegisters: [],
                cashRegistersOptions: [],
                ingredients: [],
                warehouses: [],
                selected_warehouses_group_option: null,
                selectedBusinessUnit: null,
                selectedCashRegister: null,
                selectedIngredient: null,
                selectedWarehouse: null,
                dateFrom: null,
                dateTo: null,
                setTime: false,
                html: '',
                selected_report: '',
                filters: '',
                required,
                resizeId: null,
                mainDivElement: null,
                priceLists: [],
                selectedPriceList: null,
                buyers: [],
                employees: [],
                buyer: null,
                employee: null,
                printCurrentStock: false,
                showEvents: false,
                includeAdditionalPaymentMethods: false
            }
        },
        methods: {
            unitChanged() {
                if (this.selected_report === this.REPORT_IDS.PriceList) {
                    this.loadPriceLists()
                }
            },
            async loadPriceLists() {
                if (!this.selectedBusinessUnit) {
                    return
                }

                try {
                    const res = await this.$http.get(`/api/client/v1/price_lists/business_units/${  this.selectedBusinessUnit.id}`)
                    this.priceLists = res.data ?? []
                    this.priceLists.unshift(this.getDefaultPriceList)
                    this.selectedPriceList = null

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }

            },
            showEventsCheckbox() {
                const reports_with_checkbox = [this.REPORT_IDS.EmployeesTimePerDay]

                return reports_with_checkbox.includes(this.selected_report)
            },
            showDatePicker() {
                const reports_without_date = [
                    this.REPORT_IDS.PriceList,
                    this.REPORT_IDS.InternalAct,
                    this.REPORT_IDS.ProductsIngredients,
                    this.REPORT_IDS.Ingredients,
                    this.REPORT_IDS.Employees,
                    this.REPORT_IDS.PaymentTypes,
                    this.REPORT_IDS.Discounts,
                    this.REPORT_IDS.DisplayGroups,
                    this.REPORT_IDS.Products,
                    this.REPORT_IDS.Addons,
                    this.REPORT_IDS.InventorySheet,
                    this.REPORT_IDS.WoltIntegration
                ]

                return !reports_without_date.includes(this.selected_report)
            },
            showBuyerPicker() {
                const reports_with_buyer = [this.REPORT_IDS.InvoicesByBuyer]
                return reports_with_buyer.includes(this.selected_report)
            },
            showEmployeePicker() {
                const reports_with_employee = [
                    this.REPORT_IDS.EmployeesTimePerDay,
                    this.REPORT_IDS.EmployeesTimeTogether,
                    this.REPORT_IDS.TipsByEmployee
                ]

                return reports_with_employee.includes(this.selected_report)
            },
            showTimePicker() {
                const reports_without_time = [
                    this.REPORT_IDS.PurchasedIngredients,
                    this.REPORT_IDS.PurchasedIngredientsBySuppliers,
                    this.REPORT_IDS.Stock,
                    this.REPORT_IDS.UsedIngredientsByDay,
                    this.REPORT_IDS.PriceList,
                    this.REPORT_IDS.InternalAct,
                    this.REPORT_IDS.ProductsIngredients,
                    this.REPORT_IDS.Ingredients,
                    this.REPORT_IDS.Employees,
                    this.REPORT_IDS.PaymentTypes,
                    this.REPORT_IDS.Discounts,
                    this.REPORT_IDS.DisplayGroups,
                    this.REPORT_IDS.Products,
                    this.REPORT_IDS.Addons,
                    this.REPORT_IDS.InventorySheet,
                    this.REPORT_IDS.IssuedInvoices,
                    this.REPORT_IDS.UnpaidInvoices,
                    this.REPORT_IDS.InvoicesByBuyer,
                    this.REPORT_IDS.InvoicesGroupedByBuyer,
                    this.REPORT_IDS.InvoicesInPreparation,
                    this.REPORT_IDS.UnissuedInvoices,
                    this.REPORT_IDS.WoltIntegration
                ]

                return !reports_without_time.includes(this.selected_report)
            },
            showIncludeAdditionalPaymentMethods() {
                const reports_with_checkbox = [
                    this.REPORT_IDS.EndOfDayReport,
                    this.REPORT_IDS.EndOfDayReportEachDay,
                    this.REPORT_IDS.ProductsSoldByCategory,
                    this.REPORT_IDS.PaymentTypesEmployeeTurnoverReport,
                    this.REPORT_IDS.PaymentTypesTurnover,
                    this.REPORT_IDS.PaymentTypesTurnoverEachDay,
                    this.REPORT_IDS.ProductsTurnover,
                    this.REPORT_IDS.CategoryTurnover,
                    this.REPORT_IDS.ReversalBillsTurnover,
                    this.REPORT_IDS.CashRegisterTurnover,
                    this.REPORT_IDS.MostSoldProduct,
                    this.REPORT_IDS.MostSoldProductByEmployee,
                    this.REPORT_IDS.ProductsSoldByHour
                ]

                return reports_with_checkbox.includes(this.selected_report)
            },
            calculateMainDivWidth() {
                const element = this.$refs.mainDiv

                if (element) {
                    return element.offsetWidth
                }

                return 0
            },
            async itemClicked(val, item) {
                this.selected_report = val
                this.filters = []
                if (item && item.filters && item.filters.length > 0) {
                    this.filters = item.filters
                }

                if (!this.selectedBusinessUnit) {
                    this.selectedBusinessUnit = {
                        id: this.$NilObjectId,
                        name: `${i18n.t('reporting.all_business_units')}`
                    }
                }

                if (!this.employee) {
                    this.employee = {
                        id: this.$NilObjectId,
                        name: this.$t('employee_time.all_employees')
                    }
                }

                if ((this.selected_report === this.REPORT_IDS.IngredientsReportCard) && this.selectedWarehouse && this.selectedWarehouse.id === this.$NilObjectId) {
                    this.selectedWarehouse = null
                }

                if ((this.selected_report === this.REPORT_IDS.PriceList || this.selected_report === this.REPORT_IDS.DisplayGroups || this.selected_report === this.REPORT_IDS.WoltIntegration) && this.selectedBusinessUnit && this.selectedBusinessUnit.id === this.$NilObjectId) {
                    this.selectedBusinessUnit = null
                }

                const menu = this.$refs.dropdownMenuFilters

                if (menu && this.filters.length > 0) {
                    menu.show()
                }

                if (this.filters.length === 0) {
                    await this.loadReport('html')
                }
            },
            async confirm() {
                switch (this.selected_report) {
                case this.REPORT_IDS.FursDateToDateReport:
                    await this.downloadCSV()
                    break
                default:
                    await this.loadReport('html')
                }
            },
            async loadData() {
                try {
                    const response1 = this.$http.get('/api/client/v1/business_units')
                    const response2 = this.$http.get('/api/client/v1/cash_registers?getAll=true')
                    const response3 = this.$http.get('/api/client/v1/buyers')
                    const response4 = this.$http.get('/api/client/v1/employees/')


                    const responses = await Promise.all([response1, response2, response3, response4])
                    this.businessUnits = responses[0].data ?? []
                    this.businessUnits.unshift(this.getAllBusinessUnitsHeader)

                    this.cashRegisters = responses[1].data.items ?? []

                    this.buyers = responses[2].data ?? []

                    this.employees = responses[3].data ?? []
                    this.employees.unshift({name: this.$t('employee_time.all_employees'), id: this.$NilObjectId})

                    if (this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) {
                        const response4 = this.$http.get('/api/client/v1/warehouses/')
                        const response5 = this.$http.get('/api/client/v1/ingredients/')
                        const response6 = this.$http.get('api/client/v1/invoice_config/warehouse_stock')

                        const responses = await Promise.all([response4, response5, response6])

                        this.warehouses = responses[0].data ?? []
                        this.warehouses.unshift(this.getAllWarehousesHeader)

                        this.ingredients = responses[1].data ?? []

                        this.warehouseConfig = responses[2].data

                        if (this.warehouseConfig) {
                            this.setWarehouseConfigFiltersBasedOnType(this.warehouseConfig.stock_type)
                        }
                    }


                    this.dateTo = new Date()
                    this.dateFrom = new Date().setDate(1)

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }


            },
            setWarehouseConfigFiltersBasedOnType(type) {
                if (type === 2) { //Posamezno skladisces
                    this.setFiltersForReport('purchased_ingredients_turnover', [FILTERS.Warehouses, FILTERS.DisableAllWarehouses])
                    this.setFiltersForReport('purchased_ingredients_by_suppliers', [FILTERS.Warehouses, FILTERS.DisableAllWarehouses])
                }
            },
            findItemIndexes(items, report_id) {
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]

                    if (item.id === report_id) {
                        return {
                            i_index: i,
                            j_index: null
                        }
                    }

                    if (item.child && item.child.length > 0) {
                        const found = this.findItemIndexes(item.child, report_id)
                        if (found && found.i_index) {
                            return {
                                i_index: i,
                                j_index: found.i_index
                            }
                        }
                    }
                }

                return {
                    i_index: null,
                    j_index: null
                }
            },
            setFiltersForReport(reportType, addFilters) {
                const indexes = this.findItemIndexes(this.computedData, reportType)

                if (indexes && indexes.i_index >= 0 && indexes.j_index >= 0) {
                    for (const filter of addFilters) {
                        const foundIndex = this.computedData[indexes.i_index].child[indexes.j_index].filters.indexOf(filter)

                        if (foundIndex === -1) {
                            this.computedData[indexes.i_index].child[indexes.j_index].filters.push(filter)
                        }
                    }
                }
            },
            concatDateAndTime(date, time) {
                return `${this.dayjs(date).format(DATE_FORMAT)}T${time}.000Z`
            },
            async loadReport(type) {

                const isValid = await this.$refs.validation.validate()
                let dateFromFormatted = new Date()
                let dateToFormatted = new Date()

                if (!isValid) {
                    return
                }

                const menu = this.$refs.dropdownMenuFilters

                if (menu) {
                    menu.hide()
                }

                if (!this.selected_report) {
                    return
                }

                if (this.selectedBusinessUnit && this.selectedBusinessUnit.id === this.$NilObjectId) {
                    this.selectedCashRegister = this.$t('reporting.all_cash_registers')
                }

                if (this.employee && this.employee.id === this.$NilObjectId) {
                    this.employee = this.$t('employee_time.all_employees')
                }

                if (this.selected_report === this.REPORT_IDS.PurchasedIngredients || this.selected_report === this.REPORT_IDS.PurchasedIngredientsBySuppliers) {
                    dateFromFormatted = dayjs(this.dateFrom).startOf('date').toDate()
                    dateToFormatted = dayjs(this.dateTo).startOf('date').toDate()

                } else {
                    dateFromFormatted = this.dayjs(this.dateFrom)
                    dateToFormatted = this.dayjs(this.dateTo)
                }

                const payload = {
                    start_time: dateFromFormatted.toISOString(),
                    end_time: dateToFormatted.toISOString(),
                    is_start_working_hour: this.isStartWorkingHour,
                    include_additional_payment_methods: this.includeAdditionalPaymentMethods
                }

                for (const item of this.filters) {

                    if (item === FILTERS.Cash_registers) {
                        let cash_register_id = ''

                        if (!this.selectedCashRegister) {
                            continue
                        }

                        if (this.selectedCashRegister.cash_register_id !== this.$t('reporting.all_cash_registers')) {
                            cash_register_id = this.selectedCashRegister.cash_register_id
                        }

                        payload.cash_register_id = cash_register_id
                    } else if (item === FILTERS.Business_units) {
                        if (!this.selectedBusinessUnit) {
                            return
                        }
                        payload.business_unit_id = this.selectedBusinessUnit.id
                    } else if (item === FILTERS.Ingredients) {
                        if (!this.selectedIngredient) {
                            return
                        }
                        payload.ingredient_id = this.selectedIngredient.id
                    } else if (item === FILTERS.Warehouses) {

                        if (this.selected_report === this.REPORT_IDS.InventorySheet && !this.printCurrentStock) {
                            payload.warehouse_id  = this.$NilObjectId
                            continue
                        } else if (!this.selectedWarehouse) {
                            return
                        }

                        payload.warehouse_id = this.selectedWarehouse.id
                    } else if (item === FILTERS.Warehouses_group) {
                        if (this.selected_warehouses_group_option.id === 1) {
                            payload.warehouses_report_group = true
                        } else {
                            payload.warehouses_report_group = false
                        }
                    } else if (item === FILTERS.PriceList) {

                        if (!this.selectedPriceList) {
                            return
                        }

                        payload.price_list_id = this.selectedPriceList.id
                    } else if (item === FILTERS.Buyer) {
                        if (!this.buyer) {
                            return
                        }

                        payload.buyer_id = this.buyer.id
                    }  else if (item === FILTERS.Employee) {

                        if (this.selected_report === this.REPORT_IDS.EmployeesTimePerDay) {
                            payload.show_events = this.showEvents
                        }

                        if (!this.employee) {
                            return
                        }
                        payload.employee_id = this.employee.id
                    } else if (item === FILTERS.PrintCurrentStock) {
                        payload.print_current_stock = this.printCurrentStock
                    } else if (item === FILTERS.Employee) {
                        if (this.selected_report === this.REPORT_IDS.EmployeesTimePerDay) {
                            payload.show_events = this.showEvents
                        }

                        if (!this.employee) {
                            return
                        }

                        payload.employee_id = this.employee.id
                    }
                }

                let apiUrl = ''
                let fileName = ''

                const names = []
                if (this.selectedBusinessUnit && this.selectedBusinessUnit.name) names.push(this.selectedBusinessUnit.name)
                if (this.selectedCashRegister && this.selectedCashRegister.cash_register_id) names.push(this.selectedCashRegister.cash_register_id)

                const dateFromToString = `${this.dayjs(this.dateFrom).format(DATE_FORMAT)}_${this.dayjs(this.dateTo).format(DATE_FORMAT)}`
                const combinedName = `${names.join('_')}_${dateFromToString}.${type}`

                switch (this.selected_report) {
                case this.REPORT_IDS.EndOfDayReport:
                    apiUrl = `/api/reports/v1/end_of_day/combined/${  type}`
                    fileName = `Zakljucek_blagajne_${combinedName}`
                    break
                case this.REPORT_IDS.EndOfDayReportEachDay:
                    apiUrl = `/api/reports/v1/end_of_day/each-day/${  type}`
                    fileName = `Zakljucek_blagajne_po_dnevih_${combinedName}`
                    break
                case this.REPORT_IDS.ProductsSoldByCategory:
                    apiUrl = `/api/reports/v1/printout/sold_products_by_category/${  type}`
                    fileName = `Izpis_prometa_po_kategorijah_${combinedName}`
                    break
                case this.REPORT_IDS.IngredientsReportCard:
                    apiUrl = `/api/reports/v1/product_card/${  type}`
                    fileName = `Kartica_artikla_${dateFromToString}.${type}`
                    break
                case this.REPORT_IDS.PaymentTypesEmployeeTurnoverReport:
                    apiUrl = `/api/reports/v1/printout/payment_types_employee_turnover/${  type}`
                    fileName = `obracun_po_nacinu_placila_${combinedName}`
                    break
                case this.REPORT_IDS.ProductsTurnover:
                    apiUrl = `/api/reports/v1/printout/products_turnover/${  type}`
                    fileName = `Izpis_prometa_po_izdelikh_${combinedName}`
                    break
                case this.REPORT_IDS.PaymentTypesTurnover:
                    apiUrl = `/api/reports/v1/printout/payment_types_turnover/${  type}`
                    fileName = `obracun_po_nacinu_placila_${combinedName}`
                    break
                case this.REPORT_IDS.PaymentTypesTurnoverEachDay:
                    apiUrl = `/api/reports/v1/printout/payment_types_turnover_each_day/${  type}`
                    fileName = `obracun_po_nacinu_placila_posamezno_${combinedName}`
                    break
                case this.REPORT_IDS.CategoryTurnover:
                    apiUrl = `/api/reports/v1/printout/categories_turnover/${  type}`
                    fileName = `Izpis_prometa_po_kategoriji_${combinedName}`
                    break
                case this.REPORT_IDS.CashRegisterTurnover:
                    apiUrl = `/api/reports/v1/printout/cash_register_turnover/${  type}`
                    fileName = `Izpis_promet_blagajne_${combinedName}`
                    break
                case this.REPORT_IDS.ReversalBillsTurnover:
                    apiUrl = `/api/reports/v1/printout/reversal_bills_turnover/${  type}`
                    fileName = `Izpis_storniranih_računov_${combinedName}`
                    break
                case this.REPORT_IDS.Stock:
                    apiUrl = `/api/reports/v1/printout/stock/${  type}`
                    fileName = `Zaloga_${this.selectedWarehouse.name}.${type}`
                    break
                case this.REPORT_IDS.PurchasedIngredients:
                    apiUrl = `/api/reports/v1/printout/purchased_ingredients/${  type}`
                    fileName = `Nabavljene_sestavine${dateFromToString}.${type}`
                    break
                case this.REPORT_IDS.PurchasedIngredientsBySuppliers:
                    apiUrl = `/api/reports/v1/printout/purchased_ingredients_by_suppliers/${  type}`
                    fileName = `Nabavljene_sestavine po dobaviteljih${dateFromToString}.${type}`
                    fileName = `Izpis_storniranih_računov_${combinedName}`
                    break
                case this.REPORT_IDS.MostSoldProduct:
                    apiUrl = `/api/reports/v1/printout/most_sold_product/${  type}`
                    fileName = `Izpis_najbolj_prodajan_izdelek_${combinedName}`
                    break
                case this.REPORT_IDS.ProductsSoldByHour:
                    apiUrl = `/api/reports/v1/printout/products_sold_by_hour/${  type}`
                    fileName = `Izpis_prodanih_izdelkov_po_urah_${combinedName}`
                    break
                case this.REPORT_IDS.MostSoldProductByEmployee:
                    apiUrl = `/api/reports/v1/printout/most_sold_product_by_employee/${  type}`
                    fileName = `Izpis_najbolj_prodajan_izdelek_po_prodajalcu_${combinedName}`
                    break
                case this.REPORT_IDS.UsedIngredientsByDay:
                    apiUrl = `/api/reports/v1/printout/used_ingredients_by_day/${  type}`
                    fileName = `Izpis_najbolj_prodajan_izdelek_po_prodajalcu_${combinedName}`
                    break
                case this.REPORT_IDS.PriceList:
                    apiUrl = `/api/reports/v1/printout/price_list/${  type}`
                    fileName = `Cenik.${type}`
                    break
                case this.REPORT_IDS.InternalAct:
                    apiUrl = `/api/reports/v1/printout/internal_act/${  type}`
                    fileName = `InterniAkt.${type}`
                    break
                case this.REPORT_IDS.ProductsIngredients:
                    apiUrl = `/api/reports/v1/printout/products_ingredients/${  type}`
                    fileName = `SestavineIzdelkov.${type}`
                    break
                case this.REPORT_IDS.Ingredients:
                    apiUrl = `/api/reports/v1/printout/ingredients/${  type}`
                    fileName = `Sestavine.${type}`
                    break
                case this.REPORT_IDS.Employees:
                    apiUrl = `/api/reports/v1/printout/employees/${  type}`
                    fileName = `Zaposleni.${type}`
                    break
                case this.REPORT_IDS.PaymentTypes:
                    apiUrl = `/api/reports/v1/printout/payment_types/${  type}`
                    fileName = `NaciniPlacila.${type}`
                    break
                case this.REPORT_IDS.Discounts:
                    apiUrl = `/api/reports/v1/printout/discounts/${  type}`
                    fileName = `Popusti.${type}`
                    break
                case this.REPORT_IDS.DisplayGroups:
                    apiUrl = `/api/reports/v1/printout/display_groups/${  type}`
                    fileName = `PrikazneSkupine.${type}`
                    break
                case this.REPORT_IDS.Products:
                    apiUrl = `/api/reports/v1/printout/products/${  type}`
                    fileName = `Izdelki.${type}`
                    break
                case this.REPORT_IDS.Addons:
                    apiUrl = `/api/reports/v1/printout/addons/${  type}`
                    fileName = `Dodatki.${type}`
                    break
                case this.REPORT_IDS.InventorySheet:
                    apiUrl = `/api/reports/v1/printout/inventory_sheet/${  type}`
                    fileName = `PopisniList.${type}`
                    break
                case this.REPORT_IDS.IssuedInvoices:
                    apiUrl = `/api/reports/v1/printout/issued_invoices/${  type}`
                    fileName = `IzdaneFakture.${type}`
                    break
                case this.REPORT_IDS.UnpaidInvoices:
                    apiUrl = `/api/reports/v1/printout/unpaid_invoices/${  type}`
                    fileName = `NeplacaneFakture.${type}`
                    break
                case this.REPORT_IDS.InvoicesByBuyer:
                    apiUrl = `/api/reports/v1/printout/invoices_by_buyer/${  type}`
                    fileName = `FakturaPoKupcu.${type}`
                    break
                case this.REPORT_IDS.InvoicesGroupedByBuyer:
                    apiUrl = `/api/reports/v1/printout/invoices_grouped_by_buyer/${  type}`
                    fileName = `FakturePoKupcih.${type}`
                    break
                case this.REPORT_IDS.InvoicesInPreparation:
                    apiUrl = `/api/reports/v1/printout/invoices_in_preparation/${  type}`
                    fileName = `FaktureVPripravi.${type}`
                    break
                case this.REPORT_IDS.UnissuedInvoices:
                    apiUrl = `/api/reports/v1/printout/unissued_invoices/${  type}`
                    fileName = `NeizdaneFakture.${type}`
                    break
                case this.REPORT_IDS.DeliveryNotes:
                    apiUrl = `/api/reports/v1/printout/delivery_notes/${  type}`
                    fileName = `Dobavnice.${type}`
                    break
                case this.REPORT_IDS.WoltIntegration:
                    apiUrl = `/api/reports/v1/printout/wolt_integration/${  type}`
                    fileName = `WoltIntegracija.${type}`
                    break
                case this.REPORT_IDS.Tips:
                    apiUrl = `/api/reports/v1/printout/tips/${  type}`
                    fileName = `Napitnine.${type}`
                    break
                case this.REPORT_IDS.EmployeeTips:
                    apiUrl = `/api/reports/v1/printout/tips/employee/${  type}`
                    fileName = `NapitnineZaposlen.${type}`
                    break
                case this.REPORT_IDS.TipsByEmployee:
                    apiUrl = `/api/reports/v1/printout/tips/employee/day/${  type}`
                    fileName = `NapitnineZaposlenDan.${type}`
                    break
                case this.REPORT_IDS.EmployeesTimeTogether:
                    apiUrl = `/api/reports/v1/printout/employees_time_together/${  type}`
                    fileName = `DelovniČasPoZaposlenih.${type}`
                    break
                case this.REPORT_IDS.EmployeesTimePerDay:
                    apiUrl = `/api/reports/v1/printout/employees_time_per_day/${  type}`
                    fileName = `DelovniČasPoDnevih.${type}`
                    break
                default:
                    return
                }

                this.showLoader = true
                try {
                    if (type === 'html') {

                        const response = await this.$http({
                            url: apiUrl,
                            method: 'POST',
                            data: payload
                        })

                        this.html = response.data ?? ''

                    } else if (type === 'pdf') {


                        const response = await this.$http({
                            url: apiUrl,
                            method: 'POST',
                            responseType: 'blob', // important
                            data: payload
                        })

                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('target', '_blank')
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()

                    } else if (type === 'xlsx') {
                        const response = await this.$http({
                            url: apiUrl,
                            method: 'POST',
                            responseType: 'blob', // important
                            data: payload
                        })

                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/xls'}))

                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('target', '_blank')
                        link.setAttribute('download', fileName)
                        document.body.appendChild(link)
                        link.click()
                    } else {
                        this.$printError(this.$t('print.error.invalid_type'))
                    }

                } catch (err) {
                    Sentry.captureException(err)
                    if (err.response && err.response.data && err.response.data.error === 'over-a-year') {
                        this.$printError(this.$t('print.error.more_than_year'))
                    } else if (err.response && err.response.data && err.response.data === 'no cash register added') {
                        this.$printError(this.$t('print.error.not_all_necessary_data_for_the_output_has_been_added'))
                    } else {
                        this.$printError(this.$t('print.error.on_load_data'))
                    }
                } finally {
                    this.showLoader = false
                }
            },
            async downloadCSV() {
                this.html = ''

                const isValid = await this.$refs.validation.validate()

                if (!isValid) {
                    return
                }

                const menu = this.$refs.dropdownMenuFilters

                if (menu) {
                    menu.hide()
                }        


                if (this.dayjs(this.dateFrom).isAfter(this.dateTo)) {
                    this.$printError(this.$t('print.error.date_from_bigger_date_to'))
                    return
                }             

                if (this.selectedBusinessUnit && this.selectedBusinessUnit.id === this.$NilObjectId) {
                    this.selectedCashRegister = this.$t('reporting.all_cash_registers')
                }               

                let cash_register_id = ''

                if (this.selectedCashRegister.cash_register_id !== this.$t('reporting.all_cash_registers')) {
                    cash_register_id = this.selectedCashRegister.cash_register_id
                }


                const dateFromDayjs = this.dayjs(this.dateFrom)
                const dateToDayjs = this.dayjs(this.dateTo)

                const payload = {
                    start_time: dateFromDayjs.toISOString(),
                    end_time: dateToDayjs.toISOString(),
                    business_unit_id: this.selectedBusinessUnit.id,
                    cash_register_id
                }

                let apiUrl = ''
                let fileName = ''
                const names = [this.selectedBusinessUnit.name, cash_register_id].filter(f => f)

                switch (this.selected_report) {
                case this.REPORT_IDS.FursDateToDateReport:
                    apiUrl = '/api/reports/v1/furs/csv'
                    fileName = `IzpisFurs_${names.join('_')}_${dateFromDayjs.format(DATE_FORMAT)}-${dateToDayjs.format(DATE_FORMAT)}.zip`
                    break
                default:
                    return
                }

                this.showLoader = true
                this.$http({
                    url: apiUrl,
                    method: 'POST',
                    responseType: 'blob', // important
                    data: payload
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}))

                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('target', '_blank')
                    link.setAttribute('download', fileName)

                    document.body.appendChild(link)
                    link.click()
                }).catch(() => {
                    this.$printError(this.$t('print.error.on_file_download'))
                }).finally(() => {
                    this.dateTo = new Date()
                    this.dateFrom = new Date().setDate(1)
                })
                this.showLoader = false

            },
            windowResized() {
                const menu = this.$refs.dropdownMenuFilters
                if (menu) {
                    menu.hide()
                }

                const thisIns = this
                setTimeout(function() {
                    thisIns.mainDivWidth = thisIns.calculateMainDivWidth()
                }, 600)
            }
        },

        async mounted() {
            this.mainDivWidth = this.calculateMainDivWidth()
            window.addEventListener('resize', this.windowResized)

            await this.loadData()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.windowResized)
        }
    }
</script>

<style lang="scss">

  .dropdownWidth{
    width: var(--width) !important;
  }

  .timePickerNoDate .vc-date{
    display: none;
  }

  .dropdownWidth.dropdown-menu{
    margin-top: 15px !important;
  }

  .dark-layout .vs__dropdown-option--disabled {
      background: none !important;
  }
</style>

<style>

 .cardBodyNoPadding.card .card-body{
   padding: 0 !important;
 }

 .reports .vs__dropdown-toggle{
   height: 38px !important;
 }

 .reports .vs__search{
   width: 100% !important;
 }

</style>

<style lang="scss" scoped>
   @import 'vue-select/src/scss/vue-select.scss';
</style>

<style scoped>

.textCutSelect{
  width: calc(100%) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.floating-filter{
  width: 100%;
  height: 30px !important;
}

.mainWrapper {
  padding-bottom: 50px;
}

/* .mainWrapper >>> @page {
  size: A4;
  margin: 0;
}
.mainWrapper >>> @media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
} */

.mainWrapper >>> .ft0 {
  font: 10pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft1 {
  font: 12pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft2 {
  font: 14pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft3 {
  font: 16pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft4 {
  font: 18pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft5 {
  font: 22pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft6 {
  font: 25pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .bold {
  font-weight: bold;
}

.mainWrapper >>> .left {
  text-align: left !important;
}

.mainWrapper >>> .right {
  text-align: right !important;
}

.mainWrapper >>> .center {
  text-align: center !important;
}

.mainWrapper >>> table{
  width: 100%;
  border-collapse: collapse;
  font: 12pt 'Cambria';
}

.mainWrapper >>> th{
  border: none;
  text-align: left;
  color: #575757;
  background-color: #b4bfb5;
}

.mainWrapper >>> td{
  /*border-top: solid 1px gray;*/
  border-bottom: solid 1px gray;
}

.mainWrapper >>> tr td:last-child{
  text-align: right;
}

.mainWrapper >>> tr th:last-child{
  text-align: right;
}

.mainWrapper >>> .container{
  display: flex;
}

.mainWrapper >>> .fixed{
  width: 50%;
}
.mainWrapper >>> .flex-item{
  flex-grow: 1;
}
</style>
